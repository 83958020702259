// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #0ec7cd;
$secondary : #36817b;
$accent    : #ff6200;

$dark      : #1D1D1D;

$positive  : #62cb0e;
$negative  : #C10015;
$info      : #3492cc;
$warning   : #F2C037;
