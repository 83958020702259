@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@import "text-align";
@import "form";
@import "animations";

$header-height: 60px;

* {
  touch-action: pan-x pan-y !important;
}

// app global css in SCSS form
html {
  background: #37474f;
}

body {
  width: unset;
  display: inline-block;
}

header {
  width: 100vw !important;
  height: $header-height;
}

// prevents zooming in on elements on double-tap on mobile
* {
  touch-action: manipulation;
}

.section-header {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section-label {
  font-size: 24px;
}

a {
  color: $primary;
  text-decoration: none;
}

.darkened-bg {
  background-color: rgba(0, 0, 0, 0.1);
}
.lighter-bg {
  background-color: rgba(255, 255, 255, 0.1);
}

.subtle {
  opacity: 0.5;
}
.very-subtle {
  opacity: 0.25;
}
.subtle-until-hovered {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
.very-subtle-until-hovered {
  opacity: 0.25;

  &:hover {
    opacity: 1;
  }
}

// make chrome's native date input icon white
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.text-h1 {
  font-size: 1.9rem;
  line-height: 1.9rem;
}
.text-h2 {
  font-size: 1.7rem;
  line-height: 1.7rem;
}
.text-h3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.text-h4 {
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.text-h5 {
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.text-form-caption {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.75;
}

.days-list {
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.time-field {
  display: flex;
  margin: auto;
}

.report-form-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

/* useful to keep elements in view when scrolling page horizontally (i.e.: on page with data-table) */
.sticky-left {
  position: sticky;
  left: 0;
  box-sizing: border-box;
}

/* make page container full window height */
.q-page-container {
  min-height: 100vh;
}
